<template>
  <div class="px-4">
    <v-stepper v-model="e1" non-linear class="mt-6">
      <v-stepper-header>
        <v-stepper-step editable :complete="e1 > 2" step="1">
          ADD ADMIN
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :editable="editSteppers" :complete="e1 > 2" step="2">
          ADD ADMIN ACCESS
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <h5 v-if="$vuetify.breakpoint.mdAndDown">ADD ADMIN</h5>
          <v-form ref="dform" lazy-validation @submit.prevent>
            <h2 class="secondary my-5 pa-3">ADMIN FORM</h2>
            <v-row class="mt-3">
              <v-col cols="12" sm="6">
                <b>Fullname:</b>
                <v-text-field
                  v-model.trim="dform.Fullname"
                  placeholder="Joe Ben"
                  outlined
                  dense
                  class="ml-2"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <b>Username:</b>
                <v-text-field
                  v-model.trim="dform.Username"
                  placeholder="JoeBen"
                  outlined
                  dense
                  class="ml-2"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <b>Password:</b>
                <v-text-field
                  v-model.trim="dform.Passwd"
                  placeholder="password"
                  outlined
                  :type="showPasswd ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  :append-icon="showPasswd ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="showPasswd = !showPasswd"
                  required
                  dense
                  class="ml-2"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <b>Position:</b>
                <v-text-field
                  v-model.trim="dform.Position"
                  placeholder="clerk"
                  outlined
                  dense
                  class="ml-4"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <b>Admin Level:</b>
                <v-select
                  v-model.trim="dform.AdminLevel"
                  placeholder="Select"
                  :items="admins"
                  outlined
                  dense
                  class="ml-2"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-radio-group v-model="dform.AdminStatus" row>
                  <b>Admin Status</b>
                  <v-radio
                    label="Active"
                    color="green"
                    :value="1"
                    class="ml-3"
                  ></v-radio>
                  <v-radio label="Inactive" color="red" :value="0"> </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <p v-if="dform.id" class="text-end">
                  <v-btn :loading="loading" @click="saveChanges" color="success"
                    >Save Changes</v-btn
                  >
                </p>
                <p v-else class="text-end">
                  <v-btn color="success" :loading="loading" @click="createAdmin"
                    >Add Admin</v-btn
                  >
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="2">
          <h5 class="text-end" v-if="$vuetify.breakpoint.mdAndDown">
            ADD ADMIN ACCESS
          </h5>
          <v-form ref="dform" lazy-validation @submit.prevent>
            <h2 class="secondary my-5 pa-3">CHOOSE ROUTES PERMISSION</h2>
            <h2 v-if="mobile" class="secondary my-5 pa-3">
              CHOOSE ROUTES PERMISSION
            </h2>
            <v-row>
              <v-col cols="12" class="pa-5">
                <v-row>
                  <v-col cols="12" sm="6">
                    <b>FORMS:</b>
                    <div v-for="form in forms" :key="form.title">
                      <v-checkbox
                        v-model="RouteSelected"
                        :label="form.title"
                        :value="form.id"
                      >
                      </v-checkbox>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <b>TABLES:</b>
                    <div v-for="table in tables" :key="table.title">
                      <v-checkbox
                        v-model="RouteSelected"
                        :label="table.title"
                        :value="table.id"
                      >
                      </v-checkbox>
                    </div>
                  </v-col>
                  <v-row class="pa-5" v-if="dform.id">
                    <v-btn color="primary" @click="e1 = 1"> Previous </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="routeChanges" color="success"
                      >Save Admin Access</v-btn
                    >
                  </v-row>
                  <v-row v-else class="pa-5 justify-end">
                    <v-btn color="success" @click="createAdminAccess"
                      >Add Admin Access</v-btn
                    >
                  </v-row>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
        <v-snackbar v-model="snackbar" bottom :color="snackColor">
          <p>{{ snackMsg }}</p>
          <template v-slot:action="{ attrs }">
            <v-btn dark v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, editUserMixin, editRoutesMixin } from "@/mixins";
import { mapState, mapGetters } from "vuex";
export default {
  name: "Admin",
  mixins: [snackMixin, editUserMixin, editRoutesMixin],

  props: {
    mobile: {
      type: Boolean,
    },
  },

  data() {
    return {
       showPasswd: false,
      e1: 1,
      RouteSelected: [],
      oldRouteSelected: [],
      admin_id: "",
      dform: this.initializeForm(),
    };
  },
  computed: {
    ...mapState(["admins", "forms", "tables"]),
    ...mapGetters(["getCurUser"]),
    editSteppers() {
      return !!this.dform.id;
      // return true;
    },
  },
  methods: {
    initializeForm() {
      return {
        id: "",
        Fullname: "",
        Username: "",
        Position: "",
        Passwd: "",
        AdminLevel: "",
        AdminStatus: 1,
        created_by: "",
        modified_by: "",
      };
    },

    createAdmin() {
      if (!this.$refs.dform.validate()) {
        return;
      }
      this.loading = true;
      this.dform.created_by = this.dform.modified_by = this.getCurUser;
      apiClient
        .post("/admin", this.dform)
        .then((res) => {
          if (res.data.id) {
            this.admin_id = res.data.id;
            this.dform = this.initializeForm();
            this.loading = false;
            this.displayMsg("New admin created successfully");
            this.e1 = 2;
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
        });
    },

    async createAdminAccess() {
      let data = {
        RouteSelected: this.RouteSelected,
        admin_id: this.admin_id,
      };
      try {
        const res = await apiClient.post("/route_access", data);
        if (res.data.ok) {
          this.displayMsg("Admin Access Added Successfully");
          this.admin_id = "";
          this.RouteSelected = [];
          this.e1 = 1;
        } else {
          this.displayMsg(res.data, "error");
        }
      } catch (err) {
        this.displayMsg(err, "error");
      }
    },

    async createNewAdminAccess(data) {
      try {
        const res = await apiClient.post("/route_access", data);
        if (res.data.ok) {
          this.displayMsg("Changes saved successfully");
          setTimeout(() => {
            this.$router.push({
              name: "AdminTable",
            });
          }, 2000);
        } else {
          this.displayMsg(res.data, "error");
        }
      } catch (err) {
        this.displayMsg(err, "error");
      }
    },

    async saveChanges() {
      this.dform.type = "admin";
      this.loading = true;
      this.dform.modified_by = this.getCurUser;
      await apiClient
        .put("/admin", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.displayMsg("Changes saved successfully");
            this.loading = false;
            this.e1 = 2;
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    },

    async routeChanges() {
      let newRouteSelected = [];
      let delRouteSelected = [];

      this.oldRouteSelected.forEach((id) => {
        !this.RouteSelected.includes(id) && delRouteSelected.push(id);
      });
      this.RouteSelected.forEach((id) => {
        !this.oldRouteSelected.includes(id) && newRouteSelected.push(id);
      });

      let onlyNew = !delRouteSelected.length && newRouteSelected.length;
      let onlyDel = !newRouteSelected.length && delRouteSelected.length;
      let bothNewNDel = newRouteSelected.length && delRouteSelected.length;
      if (onlyNew) {
        let data = {
          RouteSelected: newRouteSelected,
          admin_id: this.dform.id,
        };
        this.createNewAdminAccess(data);
      } else if (onlyDel) {
        let data2 = {
          RouteSelected: delRouteSelected,
          admin_id: this.dform.id,
        };
        this.deleteRoutes(data2);
      } else if (bothNewNDel) {
        let data1 = {
          RouteSelected: newRouteSelected,
          admin_id: this.dform.id,
        };
        let data2 = {
          RouteSelected: delRouteSelected,
          admin_id: this.dform.id,
        };
        this.updateAdminAccess(data2, data1);
      }
    },

    async updateAdminAccess(data2, data1) {
      try {
        const res1 = await apiClient.post(`/route_access`, data1);
        const res2 = await apiClient.patch(`/route_access`, data2);
        if (res1.data.ok && res2.data.ok) {
          this.displayMsg("Changes saved successfully");
          setTimeout(() => {
            this.$router.push({
              name: "AdminTable",
            });
          }, 2000);
        } else {
          this.displayMsg(`${res1.data}  ${res2.data}`, "error");
        }
      } catch (err) {
        this.displayMsg(err, "error");
      }
    },

    async deleteRoutes(delRouteSelected) {
      try {
        const res = await apiClient.patch("/route_access", delRouteSelected);
        if (res.data.ok) {
          this.displayMsg("Changes saved successfully");
          setTimeout(() => {
            this.$router.push({
              name: "AdminTable",
            });
          }, 2000);
        } else {
          this.displayMsg(res.data, "error");
        }
      } catch (err) {
        this.displayMsg(err, "error");
      }
    },
  },
};
</script>
